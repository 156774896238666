<div
	class="desktop-only"
>
	<span class="desktop-only__text">
		Просмотр этой страницы не доступен на мобильных устройствах!
	</span>
	<a
		href="/enterprise"
		class="desktop-only__link"
	>Перейти в меню</a>
</div>
