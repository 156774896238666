import { NgModule } from '@angular/core';
import { NotAccessForMobileComponent } from '@shared/ui-components/not-access-for-mobile/not-access-for-mobile.component';
import { AdaptiveModule } from '@shared/directives/adaptive/adaptive.module';

@NgModule({
	imports: [AdaptiveModule],
	declarations: [NotAccessForMobileComponent],
	exports: [NotAccessForMobileComponent],
})
export class NotAccessForMobileModule {}
